import { authenticatedRequest } from '@ynap/api-utils';
import invariant from 'invariant';

const postRegisterEmail = params => {
    const { baseUrl, brandId, country, clientId, email, gender, preferredLanguage } = params;

    invariant(baseUrl, 'baseUrl is a required param of postRegisterEmail()');
    invariant(brandId, 'brandId is a required param of postRegisterEmail()');
    invariant(country, 'country is a required param of postRegisterEmail()');
    invariant(clientId, 'clientId is a required param of postRegisterEmail()');
    invariant(email, 'email is a required param of postRegisterEmail()');
    invariant(gender, 'gender is a required param of postRegisterEmail()');
    invariant(preferredLanguage, 'preferredLanguage is a required param of postRegisterEmail()');

    const url = `${baseUrl}wcs/resources/store/${brandId}_${country.toLowerCase()}/guestidentity/fastuser`;

    const headers = {
        'Content-Type': 'application/json'
    };
    const body = {
        email,
        gender,
        preferredLanguage,
        registrationSource: 'Web'
    };

    return authenticatedRequest(new Request(url, { headers, method: 'POST', body: JSON.stringify(body) }), null, clientId);
};

export default postRegisterEmail;
