import { REGISTER_EMAIL_REQUEST, REGISTER_EMAIL_SUCCESS, REGISTER_EMAIL_ERROR, REGISTER_EMAIL_RESET } from '../constants/actionTypes';

// Change this to send a POST to fastUser endpoint
import { postRegisterEmail, getWCSHost } from '../../api-utils';

const registerEmailRequest = () => ({
    type: REGISTER_EMAIL_REQUEST
});

const registerEmailSuccess = () => ({
    type: REGISTER_EMAIL_SUCCESS
});

const registerEmailError = error => ({
    type: REGISTER_EMAIL_ERROR,
    error
});

const resetRegister = () => ({
    type: REGISTER_EMAIL_RESET
});

export const handleError = resError => {
    let error = {};
    if (resError) {
        resError.forEach(({ errorKey }) => {
            error.message = errorKey;
            switch (errorKey) {
                case 'ERR_USER_FULL_USER_ALREADY_EXISTS':
                    error.type = 'exists';
                    break;
                default:
                    error.type = 'global';
            }
        });
    } else {
        error.type = 'global';
    }

    return error;
};

export const register = (email, gender) => (dispatch, getState, fetch) => {
    const { config, locale } = getState();
    const { country, language } = locale;
    const { brandId, wcs } = config;
    const { baseUrl, clientId } = getWCSHost({ wcs });

    const preferredLanguage = `${language}_${country}`;

    dispatch(registerEmailRequest());

    return fetch(postRegisterEmail({ baseUrl, country, brandId, clientId, email, gender, preferredLanguage })).then(response => {
        if (response.ok) {
            return dispatch(registerEmailSuccess());
        } else {
            return response.json().then(json => {
                const error = handleError(json.errors);

                if (error) {
                    return dispatch(registerEmailError(error));
                }
            });
        }
    });
};

export const reset = () => dispatch => {
    dispatch(resetRegister());
    return;
};

const registerEmail = {
    register,
    reset
};

export default registerEmail;
